<template>
  <div>
    <a-form
      class="iotplt-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="7">
          <a-form-item label="时间">
            <a-range-picker
              style="width: 100%"
              v-decorator="['sort_time']"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import {
  formatRangeBeginDate,
  formatRangeEndDate
} from '@/utils/time'

export default {
  name: 'SearchAgentBalanceStatistic',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'transfers_search' })
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        sort_time_begin: formatRangeBeginDate(fieldsValue.sort_time),
        sort_time_end: formatRangeEndDate(fieldsValue.sort_time)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
