<template>
  <top-agent-self-balance-statistic v-if="this.$store.getters.userRole === 'top_agent'" />
  <agency-self-balance-statistic v-else-if="this.$store.getters.userRole === 'agency'" />
  <merchant-self-balance-statistic v-else-if="this.$store.getters.userRole === 'merchant'" />
</template>

<script>
import TopAgentSelfBalanceStatistic from '@/views/balance_statistics/top_agent_self/index'
import AgencySelfBalanceStatistic from '@/views/balance_statistics/agency_self/index'
import MerchantSelfBalanceStatistic from '@/views/balance_statistics/merchant_self/index'

export default {
  name: 'SideMenu',
  components: {
    TopAgentSelfBalanceStatistic,
    AgencySelfBalanceStatistic,
    MerchantSelfBalanceStatistic
  }
}
</script>
