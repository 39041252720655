import request from '@/utils/request'

// 查询客户余额记录
export function findAgentBalanceStatistics(params) {
  return request({
    url: `/agent_balance_statistics`,
    method: 'get',
    params: params
  })
}

// 查询客户余额记录详情
export function findAgentBalanceStatistic(id, params) {
  return request({
    url: `/agent_balance_statistics/${id}`,
    method: 'get',
    params: params
  })
}

// 导出余额记录
export function exportAgentBalanceStatistics(data) {
  return request({
    url: `/agent_balance_statistics/export`,
    method: 'post',
    data
  })
}
